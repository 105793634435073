import React from "react";
import parse from "html-react-parser";
import {
  StyledOverviewDivider,
  StyledOverviewDividerIcon,
  StyledPrivacyPolicyContent,
  StyledPrivacyPolicyLabel,
  StyledPrivacyPolicyParagraph,
  StyledPrivacyPolicySection,
  StyledPrivacyPolicyTitle,
  StyledPrivacyPolicyWrapper,
} from "./privacyPolicy.style";
import DividerIcon from "../../../images/smallVoltspotIcon.png";
import { StyledCenter } from "../../../utils/styledComponents/center.style";
import { LANG } from "../../../lang/lang";

const PrivacyPolicyParagraphs = (): JSX.Element => {
  const paragraphs = Object.entries(
    LANG.PL.privacyPolicySubpage.paragraphs
  ).map((e) => e[1]);
  const renderParagraphs = () => {
    return paragraphs.map((el) => (
      <StyledPrivacyPolicyParagraph key={el.id}>
        {parse(el.value)}
      </StyledPrivacyPolicyParagraph>
    ));
  };

  return (
    <StyledPrivacyPolicyWrapper>
      <StyledCenter>
        <StyledPrivacyPolicyContent>
          <StyledPrivacyPolicyTitle>
            {LANG.PL.privacyPolicySubpage.subTitle}
          </StyledPrivacyPolicyTitle>
          <StyledPrivacyPolicySection>
            <StyledPrivacyPolicyLabel>
              {LANG.PL.privacyPolicySubpage.sectionLabel}
            </StyledPrivacyPolicyLabel>
            {renderParagraphs()}
          </StyledPrivacyPolicySection>
          <StyledOverviewDivider>
            <StyledOverviewDividerIcon src={DividerIcon} />
          </StyledOverviewDivider>
        </StyledPrivacyPolicyContent>
      </StyledCenter>
    </StyledPrivacyPolicyWrapper>
  );
};

export default PrivacyPolicyParagraphs;
